import {Easing} from 'remotion';

export const withTiming = (name, config) => ({
	type: 'timing',
	name,
	from: config?.from ?? 0,
	to: config?.to ?? 1,
	duration: config?.duration ?? 30,
	easing: config?.easing ?? Easing.inOut(Easing.ease),
});

export const isTiming = (node) => node.type === 'timing';
