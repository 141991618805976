import {useMemo} from 'react';
import {Easing} from 'remotion';

import {
	useAnimation,
	withDelay,
	withParallel,
	withSequence,
	withSpring,
	withTiming,
} from '../animations';

const defaultAnimation = {};

const getAnimation = ({
	emojiAnimation,
	randomNumberSeed,
	rowDelayStart,
	fontSize,
}) => {
	const springConfig = {
		damping: 500,
	};
	const customEasing = Easing.bezier(0.1, 0.01, 0, 1.14);
	const memoizedAnimation = useMemo(() => {
		let topTo;
		let from;
		switch (emojiAnimation) {
			case 'emoji-rotate':
				return withSequence(
					withSpring('rotate', {
						from: -30,
						to: 30,
						config: {mass: 0.5, damping: 200},
					}),
					withSpring('rotate', {
						from: 30,
						to: -30,
						config: {mass: 0.5, damping: 200},
					}),
					withSpring('rotate', {
						from: -30,
						to: 30,
						config: {mass: 0.5, damping: 200},
					})
				);
			case 'emoji-fade-in':
				return withSpring('opacity', {
					from: 0.3,
					to: 1,
					config: {mass: 0.01, damping: 200},
				});
			case 'emoji-pop-in':
				return withSpring('scale', {
					from: 0.8,
					to: 1,
					config: {mass: 0.1, damping: 200},
				});
			case 'emoji-bounce-in':
				return withSpring('scale', {
					from: 1.2,
					to: 1,
					config: {mass: 0.1, damping: 200},
				});
			case 'emoji-bounce-in-wiggle':
				from = randomNumberSeed % 2 === 0 ? 10 : -10;
				topTo = 0;
				return withSequence(
					withTiming('scale', {
						from: 0.9,
						to: 1,
						duration: 3,
					}),
					withParallel(
						withSpring('top', {
							from: from,
							to: topTo,
							config: {
								stiffness: 200,
								mass: 10,
								damping: 4,
							},
						}),
						withSpring('left', {
							from: from,
							to: 0,
						})
					)
				);
			case 'emoji-scale':
				return withSpring('scale', {
					from: 0.4,
					to: 1,
					config: {mass: 0.3},
				});
			case 'emoji-slide-up':
				return withTiming('top', {
					from: 100,
					to: 20,
					easing: customEasing,
				});
			case 'emoji-slide-up-down':
				return withSequence(
					withTiming('top', {
						from: -20,
						to: -(fontSize * 2),
						duration: 3,
						easing: Easing.bezier(0.46, 0.95, 0.32, 1.05),
					}),
					withDelay(rowDelayStart > 7 ? rowDelayStart - 7 : rowDelayStart),
					withTiming('top', {
						from: -(fontSize * 2),
						to: -40,
						duration: 3,
						easing: Easing.bezier(0.46, 0.95, 0.32, 1.05),
					})
				);
			case 'emoji-slide-diagonal-bottom-right':
				return withParallel(
					withTiming('bottom', {
						from: 100,
						to: 20,
						easing: customEasing,
					}),
					withTiming('left', {
						from: 0,
						to: -80,
						easing: customEasing,
					})
				);
			case 'emoji-slide-diagonal-bottom-left':
				return withParallel(
					withTiming('bottom', {
						from: 100,
						to: 20,
						easing: customEasing,
					}),
					withTiming('left', {
						from: 0,
						to: 80,
						easing: customEasing,
					})
				);
			case 'emoji-slide-diagonal-top-right':
				return withParallel(
					withSpring('top', {
						from: 100,
						to: 20,
						config: springConfig,
					}),
					withSpring('left', {
						from: 0,
						to: -80,
						config: springConfig,
					})
				);
			case 'emoji-slide-diagonal-top-left':
				return withParallel(
					withSpring('top', {
						from: 100,
						to: 20,
						config: springConfig,
					}),
					withSpring('left', {
						from: 0,
						to: 80,
						config: springConfig,
					})
				);
			case 'emoji-slide-down':
				return withTiming('bottom', {
					from: 100,
					to: 20,
					easing: customEasing,
				});
			case 'emoji-slide-right':
				return withTiming('left', {
					from: 50,
					to: 0,
					easing: customEasing,
				});
			case 'emoji-slide-left':
				return withTiming('left', {
					from: -50,
					to: 0,
					easing: customEasing,
				});
			case 'emoji-slide-bottom-right':
				return withTiming('left', {
					from: -125,
					to: 125,
					easing: customEasing,
				});
			case 'emoji-slide-top-right':
				return withTiming('left', {
					from: -125,
					to: 125,
					easing: customEasing,
				});

			default:
				return defaultAnimation;
		}
	}, [emojiAnimation]);

	return memoizedAnimation;
};

export const useAnimatedEmojis = (props) => {
	const animation = getAnimation(props);
	const animatedValues = useAnimation(animation);

	return {
		top: animatedValues?.top ?? '',
		bottom: animatedValues?.bottom ?? '',
		left: animatedValues?.left ?? '',
		right: animatedValues?.right ?? '',
		scale: animatedValues?.scale ?? 1,
		rotate: animatedValues?.rotate ?? 0,
		opacity: 1,
	};
};
