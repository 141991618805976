import {Composition, Video, staticFile, useVideoConfig} from 'remotion';
import VideoWithSubtitle from './VideoWithSubtitle';
import jsonDate from './Subtitles/dan.json';
import './styles/colors.css';
import './styles/fonts.css';
import './styles/globals.css';
import './styles/cdn-emoji-font.css';
import './styles/templates.css';
import {loadFonts} from './fontLoader';

const FPS = 30;
const DURATION_IN_SECONDS = jsonDate[0].json.project.duration;
const DURATION_IN_FRAMES = DURATION_IN_SECONDS * FPS;

if (typeof document !== 'undefined') loadFonts();

export const RemotionRoot = () => {
	return (
		<>
			<Composition
				id="root"
				component={VideoWithSubtitle}
				durationInFrames={DURATION_IN_FRAMES}
				fps={FPS}
				width={1080}
				height={1920}
				defaultProps={{
					subtitles: jsonDate,
				}}
			/>
		</>
	);
};
