import {Easing} from 'remotion';
import {useMemo} from 'react';
import {
	useAnimation,
	withDelay,
	withParallel,
	withSequence,
	withSpring,
	withTiming,
} from '../animations';

const defaultAnimation = withSequence(
	withTiming('top', {
		from: 0,
		to: 0,
		duration: 1,
	})
);

const getAnimation = ({rowAnimation, randomNumberSeed, rowDelayStart}) => {
	let topTo;
	let from;
	const memoizedAnimation = useMemo(() => {
		switch (rowAnimation) {
			case 'row-letter-fade-in':
				return withTiming('opacity', {
					from: 0.3,
					to: 1,
					duration: 2,
					easing: Easing.ease,
				});
			case 'row-fade-in':
				return withTiming('opacity', {
					from: 0.3,
					to: 1,
					duration: 5,
					easing: Easing.ease,
				});
			case 'row-pop-in':
				return withTiming('scale', {
					from: 1.2,
					to: 1,
					duration: 3,
					easing: Easing.bezier(0.1, 0.01, 0, 1.14),
				});
			case 'row-bounce-in':
				return withTiming('scale', {
					from: 0.8,
					to: 1,
					duration: 40,
					easing: Easing.bezier(0.1, 0.01, 0, 1.14),
				});
			case 'row-scale-bounce':
				return withTiming('scale', {
					from: 0,
					to: 1,
					duration: 3,
					easing: Easing.bezier(0.1, 0.01, 0, 1.14),
				});
			case 'row-rotate-left':
				return withParallel(
					withTiming('left', {
						from: -1000,
						to: 0,
						duration: 5,
					})
				);
			case 'row-rotate-right':
				return withParallel(
					withTiming('left', {
						from: 1000,
						to: 0,
						duration: 5,
					})
				);
			case 'row-scale-slow-in':
				return withTiming('scale', {
					from: 0.9,
					to: 1,
					duration: rowDelayStart * 2,
				});
			case 'row-rotate-slow-right':
				return withTiming('rotate', {
					from: -1,
					to: 0,
					duration: rowDelayStart,
				});
			case 'row-rotate-slow-left':
				return withTiming('rotate', {
					from: 1,
					to: 0,
					duration: rowDelayStart,
				});
			case 'row-scale-rotate-right':
				return withParallel(
					withTiming('scale', {
						from: 0,
						to: 1,
						duration: 3,
					}),
					withTiming('rotate', {
						from: 30,
						to: 0,
						duration: 3,
					})
				);
			case 'row-fade-out':
				return withSequence(
					withDelay(rowDelayStart > 4 ? rowDelayStart - 4 : 1),
					withTiming('opacity', {
						from: 1,
						to: 0.2,
						duration: 4,
						easing: Easing.ease,
					})
				);
			case 'row-screw-in':
				return withSequence(
					withTiming('scale', {
						from: 0.7,
						to: 1,
						duration: 2,
					}),
					withDelay(rowDelayStart > 3 ? rowDelayStart - 3 : 1),
					withTiming('scale', {
						from: 1,
						to: 0.3,
						duration: 2,
					})
				);
			case 'row-typewriter':
				return withParallel(
					withTiming('scale', {
						from: 0,
						to: 1,
						duration: 3,
					}),
					withTiming('rotate', {
						from: 30,
						to: 0,
						duration: 3,
					})
				);
			case 'row-rotate-wiggle':
				from = randomNumberSeed % 2 === 0 ? 15 : -15;
				topTo = 0;
				return withParallel(
					withSpring('top', {
						from: from,
						to: topTo,
						config: {
							stiffness: 200,
							mass: 10,
							damping: 4,
						},
					}),
					withSpring('left', {
						from: from,
						to: 0,
						config: {
							stiffness: 200,
							mass: 10,
							damping: 4,
						},
					})
				);
			case 'row-rotate-wiggle-scale':
				from = randomNumberSeed % 2 === 0 ? 15 : -15;
				topTo = 0;
				return withParallel(
					withTiming('scale', {
						from: 0.9,
						to: 1,
						duration: 3,
					}),
					withSpring('top', {
						from: from,
						to: topTo,
						config: {
							stiffness: 200,
							mass: 10,
							damping: 4,
						},
					}),
					withSpring('left', {
						from: from,
						to: 0,
						config: {
							stiffness: 200,
							mass: 10,
							damping: 4,
						},
					})
				);

			case 'row-pop-in-zoom':
				return withSequence(
					withTiming('scale', {
						from: 0.9,
						to: 1.1,
						duration: 2,
						easing: Easing.bezier(0.1, 0.01, 0, 1.14),
					}),
					withTiming('scale', {
						from: 1.1,
						to: 1,
						duration: 2,
						easing: Easing.bezier(0.1, 0.01, 0, 1.14),
					})
				);
			case 'row-letter-spacing-bounce-in':
				return withTiming('letterSpacing', {
					from: -20,
					to: 0,
					duration: 3,
					easing: Easing.bezier(0.1, 0.01, 0.14, 1.74),
				});
			case 'row-letter-spacing-large-in':
				from = randomNumberSeed % 2 === 0 ? 4 : -4;
				topTo = 0;
				return withSequence(
					withTiming('letterSpacing', {
						from: -4,
						to: 0,
						duration: 3,
						easing: Easing.ease,
					}),
					withParallel(
						withTiming('top', {
							from: from,
							to: topTo,
							duration: rowDelayStart > 6 ? rowDelayStart - 6 : 1,
							easing: Easing.ease,
						}),
						withTiming('left', {
							from: from,
							to: 0,
							duration: rowDelayStart > 6 ? rowDelayStart - 6 : 1,
							easing: Easing.ease,
						})
					),
					withTiming('letterSpacing', {
						from: 0,
						to: -4,
						duration: 1,
						easing: Easing.ease,
					})
				);

			case 'row-letter-spacing-in':
				from = randomNumberSeed % 2 === 0 ? 4 : -4;
				topTo = 0;
				return withSequence(
					withTiming('letterSpacing', {
						from: -5,
						to: -2,
						duration: 3,
						easing: Easing.ease,
					}),
					withParallel(
						withTiming('top', {
							from: from,
							to: topTo,
							duration: rowDelayStart > 6 ? rowDelayStart - 6 : 1,
							easing: Easing.ease,
						}),
						withTiming('left', {
							from: from,
							to: 0,
							duration: rowDelayStart > 6 ? rowDelayStart - 6 : 1,
							easing: Easing.ease,
						})
					),
					withTiming('letterSpacing', {
						from: -2,
						to: -5,
						duration: 3,
						easing: Easing.ease,
					})
				);

			default:
				return defaultAnimation;
		}
	}, [rowAnimation]);

	return memoizedAnimation;
};

export const useAnimatedRows = (props) => {
	const animation = getAnimation(props);
	const animatedValues = useAnimation(animation);
	return {
		top: animatedValues?.top ?? 0,
		left: animatedValues?.left ?? 0,
		scale: animatedValues?.scale ?? 1,
		rotate: animatedValues?.rotate ?? 0,
		opacity: animatedValues?.opacity ?? 1,
		letterSpacing: animatedValues?.letterSpacing ?? 1,
	};
};
