import {Easing} from 'remotion';
import {
	useAnimation,
	withDelay,
	withParallel,
	withSequence,
	withSpring,
	withTiming,
} from '../animations';

const defaultAnimation = {};

const getAnimation = ({wordAnimation, wordDelayStart}) => {
	switch (wordAnimation) {
		case 'word-show-in':
			return withSequence(
				withDelay(wordDelayStart > 1 ? wordDelayStart - 1 : 1),
				withTiming('opacity', {
					from: 0,
					to: 1,
					duration: 6,
					easing: Easing.ease,
				})
			);
		case 'word-slide-up':
			return withSequence(
				withDelay(wordDelayStart > 1 ? wordDelayStart - 1 : 1),
				withParallel(
					withTiming('opacity', {
						from: 0,
						to: 1,
						duration: 3,
						easing: Easing.ease,
					}),
					withTiming('top', {
						from: 30,
						to: 0,
						duration: 3,
					})
				)
			);
		case 'word-fade-in':
			return withSequence(
				withDelay(wordDelayStart > 0 ? wordDelayStart : 1),
				withTiming('opacity', {
					from: 0.3,
					to: 1,
					duration: 6,
					easing: Easing.ease,
				})
			);
		case 'word-opacity-30':
			return withTiming('opacity', {
				from: 0.3,
				to: 0.3,
				duration: 1,
			});
		default:
			break;
	}
	return defaultAnimation;
};

export const useAnimatedWords = (props) => {
	const animation = getAnimation(props);
	// @ts-ignore
	const animatedValues = useAnimation(animation);

	return {
		scale: animatedValues?.scale ?? 1,
		opacity: animatedValues?.opacity ?? 1,
		top: animatedValues?.top ?? 0,
	};
};
