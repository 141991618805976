import {useVideoConfig} from 'remotion';
import {interpolate, spring, useCurrentFrame, measureSpring} from 'remotion';
import {
	isStagger,
	isParallel,
	isSequence,
	isSpring,
	isTiming,
	isMarker,
} from './lib';

const CLAMP = {
	extrapolateLeft: 'clamp',
	extrapolateRight: 'clamp',
};

const computeDuration = (root, frameRate = 30) => {
	const timelines = {};
	process(root, timelines, frameRate, 0);
	return Math.floor(
		Math.max(
			...Object.values(timelines).map(
				(timeline) => timeline[timeline.length - 1].inputRange[1]
			)
		)
	);
};

const process = (root, timelines, frameRate, frame) => {
	if (isStagger(root)) {
		root.children.forEach((child, index) => {
			process(child, timelines, frameRate, frame + index * root.delay);
		});
	} else if (isParallel(root)) {
		root.children.forEach((child) => {
			process(child, timelines, frameRate, frame);
		});
	} else if (isSequence(root)) {
		root.children.reduce((delay, child) => {
			process(child, timelines, frameRate, frame + delay);
			return delay + computeDuration(child, frameRate);
		}, 0);
	} else if (isSpring(root)) {
		const {config, from, to, name} = root;
		if (!timelines[name]) {
			timelines[name] = [];
		}
		timelines[name].push({
			inputRange: [
				frame,
				frame + measureSpring({fps: frameRate, config, from, to}),
			],
			outputRange: [from, to],
			config: config ?? {},
		});
	} else if (isTiming(root)) {
		const {easing, duration, from, to, name} = root;
		if (!timelines[name]) {
			timelines[name] = [];
		}
		timelines[name].push({
			inputRange: [frame, frame + duration],
			outputRange: [from, to],
			easing,
		});
	} else if (isMarker(root)) {
		const {name} = root;
		if (!timelines[name]) {
			timelines[name] = [];
		}
		timelines[name].push({
			inputRange: [0, 1],
			outputRange: [frame, frame],
		});
	}
};

export const useAnimation = (root, still) => {
	const currentFrame = useCurrentFrame();
	const {fps} = useVideoConfig();
	const frame = still ?? currentFrame;
	const result = {};
	const timelines = {};
	process(root, timelines, fps, 0);
	Object.keys(timelines).map((name) => {
		const timeline = timelines[name];
		const before = timeline.filter((event) => event.inputRange[1] < frame);
		const events = timeline.filter(
			(e) => frame >= e.inputRange[0] && frame <= e.inputRange[1]
		);
		const event =
			// eslint-disable-next-line no-nested-ternary
			events.length === 0
				? before.length === 0
					? timeline[0]
					: before[before.length - 1]
				: events[0];
		if (event.config) {
			result[name] =
				Math.round(
					spring({
						fps,
						frame: frame - event.inputRange[0],
						from: event.outputRange[0],
						to: event.outputRange[1],
						config: event.config,
					}) * 10000
				) / 10000;
		} else {
			result[name] = interpolate(frame, event.inputRange, event.outputRange, {
				...CLAMP,
				easing: event.easing,
			});
		}
	});
	return result;
};
