import {useCurrentFrame} from 'remotion';
import Word from './Word';

export default function Rows({rows, animationClass, frame}) {
	const displayByLetter =
		animationClass === 'row-typewriter' ||
		animationClass === 'row-letter-fade-in';

	return rows.map((row, index) => (
		<div key={`row-${index}`} className="row">
			{row.widgets.map((word, wordIndex) => (
				<Word
					key={`word-${wordIndex}-row-${index}`}
					wordIndex={wordIndex}
					word={word}
					row={row}
					displayByLetter={displayByLetter}
					frame={frame}
				/>
			))}
		</div>
	));
}
