import {continueRender, delayRender, staticFile} from 'remotion';

const defaultFonts = [
	{
		name: 'Montserrat',
		fontType: 'google',
	},
	{
		name: 'Bangers',
		fontType: 'google',
	},
	{
		name: 'Nunito',
		fontType: 'google',
	},
	{
		name: 'Poppins',
		fontType: 'google',
	},
	{
		name: 'Roboto',
		fontType: 'google',
	},
	{
		name: 'Raleway',
		fontType: 'google',
	},
	{
		name: 'Cairo',
		fontType: 'google',
	},
	{
		name: 'Rubik',
		fontType: 'google',
	},
	{
		name: 'Noto Sans',
		fontType: 'google',
	},
	{
		name: 'Fira Sans Condensed',
		fontType: 'google',
	},
	{
		name: 'Gabarito',
		fontType: 'google',
	},
	{
		name: 'DM Serif Display',
		fontType: 'google',
	},
	{
		name: 'eurostile',
		fontType: 'local',
	},
	{
		name: 'komika',
		fontType: 'local',
	},
	{
		name: 'opinion',
		fontType: 'local',
	},
	{
		name: 'thebold',
		fontType: 'local',
	},
	{
		name: 'ttfors',
		fontType: 'local',
	},
];

const getFontPath = (fontName) => {
	switch (fontName) {
		case 'Montserrat':
			return require('@remotion/google-fonts/Montserrat');
		case 'Bangers':
			return require('@remotion/google-fonts/Bangers');
		case 'Nunito':
			return require('@remotion/google-fonts/Nunito');
		case 'Poppins':
			return require('@remotion/google-fonts/Poppins');
		case 'Roboto':
			return require('@remotion/google-fonts/Roboto');
		case 'Raleway':
			return require('@remotion/google-fonts/Raleway');
		case 'Cairo':
			return require('@remotion/google-fonts/Cairo');
		case 'Rubik':
			return require('@remotion/google-fonts/Rubik');
		case 'Noto Sans':
			return require('@remotion/google-fonts/NotoSans');
		case 'Fira Sans Condensed':
			return require('@remotion/google-fonts/FiraSansCondensed');
		case 'Gabarito':
			return require('@remotion/google-fonts/Gabarito');
		case 'DM Serif Display':
			return require('@remotion/google-fonts/DMSerifDisplay');

		default:
			return fontName; // Fallback to font name if not found
	}
};

const fontParams = defaultFonts.map((fontData) => {
	const font = getFontPath(fontData.name);
	const subsets = ['latin', 'latin-ext'];

	if (fontData.name === 'Roboto') {
		subsets.push('greek');
		subsets.push('cyrillic');
	}
	if (fontData.name === 'Montserrat') {
		subsets.push('cyrillic');
	}
	return {
		font,
		fontType: fontData.fontType,
		options: {
			subsets,
		},
	};
});

const getLocalFontFace = (fontName) => {
	return new FontFace(
		fontName,
		`url('${staticFile(
			`/assets/fonts/${fontName.toLowerCase()}.woff2`
		)}') format('woff2')`
	);
};

const waitForFont = delayRender(); // You need to provide your implementation of this function

export function notUndefined(value) {
	return value !== undefined;
}

export const loadFonts = async () => {
	const loadFontsPromises = fontParams
		.map(({font, fontType, style, options}) => {
			if (typeof font === 'string') {
				if (fontType === 'local') {
					const fontFace = getLocalFontFace(font);
					return fontFace.load().then(() => document.fonts.add(fontFace));
				}
			} else {
				return font.loadFont(style, options);
			}
		})
		.filter(notUndefined);

	await Promise.all(loadFontsPromises);

	continueRender(waitForFont);
};
