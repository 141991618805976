import {OffthreadVideo, staticFile} from 'remotion';
import {useAnimatedEmojis} from './Hooks/useAnimatedEmojis';

const convertEmojiCode = (emoji) => {
	const codePoint = emoji.codePointAt(0).toString(16);
	return codePoint;
};

export default function Emoji({
	emojiAnimation,
	randomNumberSeed,
	duration,
	fontSize,
	emoji,
	size,
}) {
	const {top, bottom, left, right, scale, rotate, opacity} = useAnimatedEmojis({
		emojiAnimation,
		randomNumberSeed,
		rowDelayStart: duration,
		fontSize: fontSize,
	});
	const transform = `scale(${scale}) rotate(${rotate}deg)`;
	const convertedEmoji = emoji && convertEmojiCode(emoji);
	const emojiWidth = Math.floor(fontSize * 2.1) * (size || 1);

	return (
		<div
			className="emoji emoji-font active border-none"
			style={{
				top,
				bottom,
				left,
				right,
				opacity,
				transform,
			}}
		>
			{emojiAnimation &&
			convertedEmoji &&
			availableAnimatedEmojis.includes(convertedEmoji) ? (
				<OffthreadVideo
					style={{width: `${emojiWidth}px`}} // Using the calculated width
					className="inline-block"
					src={staticFile(`/assets/animatedEmojis/${convertedEmoji}.webm`)}
				/>
			) : (
				emoji
			)}
		</div>
	);
}

const availableAnimatedEmojis = [
	'1f192',
	'1f193',
	'1f195',
	'1f197',
	'1f199',
	'1f1e6-1f1ea',
	'1f1e6-1f1f2',
	'1f1e6-1f1f7',
	'1f1e6-1f1f9',
	'1f1e7-1f1ea',
	'1f1e7-1f1eb',
	'1f1e7-1f1ec',
	'1f1e7-1f1ef',
	'1f1e7-1f1f7',
	'1f1e7-1f1f8',
	'1f1e7-1f1fe',
	'1f1e8-1f1ee',
	'1f1e8-1f1f2',
	'1f1e8-1f1f3',
	'1f1e8-1f1ff',
	'1f1e9-1f1ea',
	'1f1e9-1f1ef',
	'1f1ea-1f1ea',
	'1f1ea-1f1ed',
	'1f1ea-1f1f8',
	'1f1eb-1f1f7',
	'1f1ec-1f1e6',
	'1f1ec-1f1e7',
	'1f1ec-1f1ed',
	'1f1ec-1f1f3',
	'1f1ec-1f1f6',
	'1f1ec-1f1fc',
	'1f1ed-1f1fa',
	'1f1ee-1f1ea',
	'1f1ee-1f1f3',
	'1f1ee-1f1f9',
	'1f1ef-1f1f4',
	'1f1ef-1f1f5',
	'1f1f0-1f1f7',
	'1f1f1-1f1f9',
	'1f1f1-1f1fa',
	'1f1f2-1f1e6',
	'1f1f2-1f1e8',
	'1f1f2-1f1ec',
	'1f1f2-1f1f1',
	'1f1f2-1f1f2',
	'1f1f2-1f1ff',
	'1f1f3-1f1ec',
	'1f1f3-1f1f1',
	'1f1f5-1f1ea',
	'1f1f5-1f1ed',
	'1f1f5-1f1f1',
	'1f1f5-1f1f8',
	'1f1f7-1f1f4',
	'1f1f7-1f1fa',
	'1f1f8-1f1e6',
	'1f1f8-1f1e9',
	'1f1f8-1f1ec',
	'1f1f8-1f1f1',
	'1f1f8-1f1f3',
	'1f1f8-1f1f4',
	'1f1f8-1f1f8',
	'1f1f8-1f1f9',
	'1f1f8-1f1fd',
	'1f1f9-1f1e9',
	'1f1fa-1f1e6',
	'1f1fa-1f1f8',
	'1f1fa-1f1ff',
	'1f1fb-1f1f3',
	'1f1fe-1f1ea',
	'1f308',
	'1f311',
	'1f312',
	'1f313',
	'1f314',
	'1f315',
	'1f317',
	'1f318',
	'1f31a',
	'1f31b',
	'1f31c',
	'1f31d',
	'1f31e',
	'1f31f',
	'1f321-fe0f',
	'1f324-fe0f',
	'1f325-fe0f',
	'1f326-fe0f',
	'1f327-fe0f',
	'1f328-fe0f',
	'1f329-fe0f',
	'1f32d',
	'1f32e',
	'1f331',
	'1f332',
	'1f333',
	'1f334',
	'1f335',
	'1f337',
	'1f338',
	'1f339',
	'1f33a',
	'1f33c',
	'1f33f',
	'1f340',
	'1f34c',
	'1f353',
	'1f354',
	'1f355',
	'1f356',
	'1f357',
	'1f358',
	'1f359',
	'1f35f',
	'1f361',
	'1f362',
	'1f363',
	'1f365',
	'1f366',
	'1f369',
	'1f36a',
	'1f36b',
	'1f36d',
	'1f36e',
	'1f370',
	'1f371',
	'1f373',
	'1f377',
	'1f378',
	'1f379',
	'1f37c',
	'1f37d-fe0f',
	'1f37e',
	'1f37f',
	'1f382',
	'1f383',
	'1f384',
	'1f385',
	'1f386',
	'1f387',
	'1f388',
	'1f389',
	'1f38a',
	'1f393',
	'1f396-fe0f',
	'1f397-fe0f',
	'1f399-fe0f',
	'1f39f-fe0f',
	'1f3a2',
	'1f3a4',
	'1f3a8',
	'1f3a9',
	'1f3ab',
	'1f3ac',
	'1f3ad',
	'1f3ae',
	'1f3b5',
	'1f3b6',
	'1f3c0',
	'1f3c1',
	'1f3c5',
	'1f3c6',
	'1f3d5-fe0f',
	'1f3d6-fe0f',
	'1f3db-fe0f',
	'1f3dd-fe0f',
	'1f3e0',
	'1f3f3-fe0f',
	'1f3f4-200d-2620-fe0f',
	'1f3f4',
	'1f3f5-fe0f',
	'1f402',
	'1f404',
	'1f406',
	'1f407',
	'1f408-200d-2b1b',
	'1f408',
	'1f40c',
	'1f40d',
	'1f414',
	'1f419',
	'1f41b',
	'1f41c',
	'1f41d',
	'1f41e',
	'1f41f',
	'1f420',
	'1f422',
	'1f423',
	'1f424',
	'1f425',
	'1f426',
	'1f427',
	'1f428',
	'1f42d',
	'1f430',
	'1f431',
	'1f433',
	'1f434',
	'1f435',
	'1f436',
	'1f437',
	'1f438',
	'1f439',
	'1f43b-200d-2744-fe0f',
	'1f43b',
	'1f43c',
	'1f43d',
	'1f43e',
	'1f440',
	'1f442',
	'1f443',
	'1f444',
	'1f445',
	'1f446',
	'1f447',
	'1f448',
	'1f449',
	'1f44a',
	'1f44b',
	'1f44c',
	'1f44d',
	'1f44e',
	'1f44f',
	'1f450',
	'1f451',
	'1f45b',
	'1f45c',
	'1f460',
	'1f463',
	'1f464',
	'1f465',
	'1f468-200d-1f3eb',
	'1f468-200d-1f469-200d-1f467-200d-1f466',
	'1f468-200d-1f4bb',
	'1f468-200d-2695-fe0f',
	'1f469-200d-1f4bb',
	'1f469-200d-2695-fe0f',
	'1f46e-200d-2640-fe0f',
	'1f46e-200d-2642-fe0f',
	'1f475',
	'1f476',
	'1f479',
	'1f47a',
	'1f47b',
	'1f47d',
	'1f47e',
	'1f47f',
	'1f480',
	'1f483',
	'1f485',
	'1f489',
	'1f48a',
	'1f48b',
	'1f48c',
	'1f48e',
	'1f490',
	'1f493',
	'1f494',
	'1f495',
	'1f496',
	'1f497',
	'1f498',
	'1f499',
	'1f49a',
	'1f49b',
	'1f49c',
	'1f49d',
	'1f49e',
	'1f49f',
	'1f4a1',
	'1f4a2',
	'1f4a3',
	'1f4a4',
	'1f4a5',
	'1f4a9',
	'1f4aa',
	'1f4ab',
	'1f4ac',
	'1f4ad',
	'1f4af',
	'1f4b0',
	'1f4b1',
	'1f4b8',
	'1f4bb',
	'1f4bc',
	'1f4c1',
	'1f4c5',
	'1f4c8',
	'1f4c9',
	'1f4ca',
	'1f4d6',
	'1f4da',
	'1f4dd',
	'1f4de',
	'1f4e3',
	'1f4e4',
	'1f4e5',
	'1f4f0',
	'1f4f1',
	'1f4f2',
	'1f4fa',
	'1f50d',
	'1f50e',
	'1f510',
	'1f511',
	'1f51d',
	'1f51e',
	'1f525',
	'1f52c',
	'1f52d',
	'1f52e',
	'1f54a-fe0f',
	'1f577-fe0f',
	'1f578-fe0f',
	'1f57a',
	'1f590-fe0f',
	'1f595',
	'1f596',
	'1f5a4',
	'1f5a8-fe0f',
	'1f5c2-fe0f',
	'1f5dd-fe0f',
	'1f5e3-fe0f',
	'1f5ef-fe0f',
	'1f5f3-fe0f',
	'1f5ff',
	'1f600',
	'1f601',
	'1f602',
	'1f603',
	'1f605',
	'1f606',
	'1f607',
	'1f608',
	'1f609',
	'1f60a',
	'1f60b',
	'1f60c',
	'1f60e',
	'1f60f',
	'1f610',
	'1f611',
	'1f612',
	'1f613',
	'1f614',
	'1f615',
	'1f616',
	'1f617',
	'1f618',
	'1f61a',
	'1f61b',
	'1f61c',
	'1f61d',
	'1f61e',
	'1f61f',
	'1f620',
	'1f621',
	'1f622',
	'1f623',
	'1f624',
	'1f625',
	'1f626',
	'1f627',
	'1f628',
	'1f629',
	'1f62a',
	'1f62b',
	'1f62c',
	'1f62d',
	'1f62e-200d-1f4a8',
	'1f62e',
	'1f62f',
	'1f630',
	'1f631',
	'1f632',
	'1f633',
	'1f634',
	'1f635-200d-1f4ab',
	'1f635',
	'1f636-200d-1f32b-fe0f',
	'1f636',
	'1f637',
	'1f638',
	'1f639',
	'1f63a',
	'1f63b',
	'1f63c',
	'1f63d',
	'1f63e',
	'1f63f',
	'1f640',
	'1f641',
	'1f642',
	'1f643',
	'1f644',
	'1f648',
	'1f649',
	'1f64a',
	'1f64c',
	'1f64f',
	'1f680',
	'1f682',
	'1f691',
	'1f693',
	'1f695',
	'1f697',
	'1f6a9',
	'1f6b9',
	'1f6ba',
	'1f6bc',
	'1f6c2',
	'1f6c3',
	'1f6c4',
	'1f6c5',
	'1f6cd-fe0f',
	'1f6d2',
	'1f6e5-fe0f',
	'1f90c',
	'1f90d',
	'1f90e',
	'1f90f',
	'1f910',
	'1f911',
	'1f912',
	'1f913',
	'1f914',
	'1f915',
	'1f916',
	'1f917',
	'1f918',
	'1f919',
	'1f91a',
	'1f91b',
	'1f91c',
	'1f91d',
	'1f91e',
	'1f91f',
	'1f920',
	'1f921',
	'1f922',
	'1f923',
	'1f924',
	'1f925',
	'1f926-200d-2642-fe0f',
	'1f926',
	'1f927',
	'1f928',
	'1f92a',
	'1f92b',
	'1f92c',
	'1f92d',
	'1f92e',
	'1f92f',
	'1f930',
	'1f932',
	'1f936',
	'1f937-200d-2640-fe0f',
	'1f937-200d-2642-fe0f',
	'1f937',
	'1f942',
	'1f943',
	'1f947',
	'1f948',
	'1f949',
	'1f959',
	'1f95b',
	'1f95e',
	'1f964',
	'1f967',
	'1f968',
	'1f96a',
	'1f96b',
	'1f970',
	'1f971',
	'1f972',
	'1f973',
	'1f974',
	'1f975',
	'1f976',
	'1f978',
	'1f979',
	'1f97a',
	'1f981',
	'1f983',
	'1f984',
	'1f986',
	'1f987',
	'1f989',
	'1f98a',
	'1f98b',
	'1f98c',
	'1f98d',
	'1f990',
	'1f991',
	'1f992',
	'1f993',
	'1f994',
	'1f995',
	'1f996',
	'1f997',
	'1f998',
	'1f999',
	'1f99c',
	'1f99d',
	'1f99e',
	'1f99f',
	'1f9a0',
	'1f9a2',
	'1f9a9',
	'1f9ac',
	'1f9ad',
	'1f9b5',
	'1f9b6',
	'1f9b7',
	'1f9bb',
	'1f9be',
	'1f9bf',
	'1f9c1',
	'1f9c3',
	'1f9c9',
	'1f9cb',
	'1f9d0',
	'1f9d1-200d-1f384',
	'1f9d1-200d-1f4bb',
	'1f9db-200d-2640-fe0f',
	'1f9db-200d-2642-fe0f',
	'1f9db',
	'1f9df-200d-2640-fe0f',
	'1f9df-200d-2642-fe0f',
	'1f9df',
	'1f9e1',
	'1f9e8',
	'1f9ea',
	'1f9ed',
	'1f9ee',
	'1f9f0',
	'1f9f3',
	'1f9fb',
	'1f9fc',
	'1f9fd',
	'1fa7a',
	'1fa84',
	'1fa96',
	'1fa99',
	'1faa9',
	'1faaa',
	'1fab2',
	'1fab3',
	'1fac2',
	'1fad7',
	'1fae0',
	'1fae1',
	'1fae2',
	'1fae3',
	'1fae4',
	'1fae5',
	'1fae6',
	'1faf0',
	'1faf1',
	'1faf2',
	'1faf3',
	'1faf4',
	'1faf5',
	'1faf6',
	'203c-fe0f',
	'2049-fe0f',
	'231b',
	'2328-fe0f',
	'23f3',
	'2600-fe0f',
	'2601-fe0f',
	'2603-fe0f',
	'260e-fe0f',
	'2611-fe0f',
	'2615',
	'2618-fe0f',
	'261d-fe0f',
	'2620-fe0f',
	'2639-fe0f',
	'263a-fe0f',
	'2648',
	'2649',
	'264a',
	'264b',
	'264c',
	'264d',
	'264e',
	'264f',
	'2650',
	'2651',
	'2652',
	'2653',
	'2668-fe0f',
	'26a1',
	'26b0-fe0f',
	'26bd',
	'26c4',
	'26c5',
	'26c8-fe0f',
	'26ce',
	'2705',
	'2708-fe0f',
	'2709-fe0f',
	'270a',
	'270b',
	'270c-fe0f',
	'270d-fe0f',
	'270f-fe0f',
	'2714-fe0f',
	'2728',
	'2744-fe0f',
	'274c',
	'2753',
	'2754',
	'2755',
	'2757',
	'2763-fe0f',
	'2764-fe0f-200d-1f525',
	'2764-fe0f-200d-1fa79',
	'2764-fe0f',
	'2b50',
];
