import {
	AbsoluteFill,
	Sequence,
	Video,
	staticFile,
	useCurrentFrame,
} from 'remotion';
import Subtitle from './Subtitle';
import {useEffect} from 'react';

export default function VideoWithSubtitle({subtitles}) {
	const frame = useCurrentFrame();
	const {inputProps} = subtitles[0].json;

	useEffect(() => {
		const highlight_style = inputProps.highlight_style;
		const strokeColor = inputProps.config_style.strokeColor;

		// Update CSS variables whenever highlight_style changes
		document.documentElement.style.setProperty(
			'--main-color',
			highlight_style.mainColor
		);
		document.documentElement.style.setProperty(
			'--second-color',
			highlight_style.secondColor
		);
		document.documentElement.style.setProperty(
			'--third-color',
			highlight_style.thirdColor
		);
		document.documentElement.style.setProperty('--stroke-color', strokeColor);
	}, [inputProps.highlight_style, inputProps.config_style.strokeColo]);

	return (
		<div>
			<Video src={staticFile('sample-input.mp4')} />
			{subtitles[0].json.inputProps.chunks.map((chunk, index) => (
				<Sequence
					key={chunk.start_caption}
					from={chunk.start_caption}
					durationInFrames={chunk.end_caption - chunk.start_caption}
				>
					<Subtitle
						rows={chunk.rows}
						emoji={chunk.emoji}
						className={chunk.classes}
						configStyle={inputProps.config_style}
						animationClass={chunk.row_animations}
						emojiAnimation={chunk.emoji_animations}
						randomNumberSeed={index}
						duration={chunk.end_caption - chunk.start_caption}
						frame={frame}
						motionBlurActive={inputProps.motion_blur_active}
						size={chunk.size}
					/>
				</Sequence>
			))}
		</div>
	);
}
