// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/cleancut.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/flowless.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/hormozi3.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/nickson.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/oussama.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/saveur.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/ali.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/submagic_business.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/beast.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/submagic.css";
import ___CSS_LOADER_AT_RULE_IMPORT_10___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/ella.css";
import ___CSS_LOADER_AT_RULE_IMPORT_11___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/umi.css";
import ___CSS_LOADER_AT_RULE_IMPORT_12___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/noah.css";
import ___CSS_LOADER_AT_RULE_IMPORT_13___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/tracy.css";
import ___CSS_LOADER_AT_RULE_IMPORT_14___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/luke.css";
import ___CSS_LOADER_AT_RULE_IMPORT_15___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/dan.css";
import ___CSS_LOADER_AT_RULE_IMPORT_16___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/maya.css";
import ___CSS_LOADER_AT_RULE_IMPORT_17___ from "-!../../node_modules/css-loader/dist/cjs.js!./templates/karl.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_12___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_13___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_14___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_15___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_16___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_17___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subtitle {\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n.emoji-font {\n\tfont-family: 'Apple Color Emoji';\n}\n\n.subtitle .sb-text-shadow-sm {\n\tz-index: 10;\n\tposition: relative;\n}\n\n.rtl {\n\tdirection: rtl;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/templates.css"],"names":[],"mappings":"AAmBA;CACC,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC,gCAAgC;AACjC;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,cAAc;AACf","sourcesContent":["@import './templates/cleancut.css';\n@import './templates/flowless.css';\n@import './templates/hormozi3.css';\n@import './templates/nickson.css';\n@import './templates/oussama.css';\n@import './templates/saveur.css';\n@import './templates/ali.css';\n@import './templates/submagic_business.css';\n@import './templates/beast.css';\n@import './templates/submagic.css';\n@import './templates/ella.css';\n@import './templates/umi.css';\n@import './templates/noah.css';\n@import './templates/tracy.css';\n@import './templates/luke.css';\n@import './templates/dan.css';\n@import './templates/maya.css';\n@import './templates/karl.css';\n\n.subtitle {\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n.emoji-font {\n\tfont-family: 'Apple Color Emoji';\n}\n\n.subtitle .sb-text-shadow-sm {\n\tz-index: 10;\n\tposition: relative;\n}\n\n.rtl {\n\tdirection: rtl;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
