import {useMemo} from 'react';
import {useVideoConfig} from 'remotion';
import AnimatedWords from './AnimatedWords';

export default function Word({word, row, wordIndex, displayByLetter, frame}) {
	const letters = useMemo(() => word.text.split(''), [word.text]);
	const {width, fps} = useVideoConfig();
	const isWidthGreaterThan700 = width > 700;

	const getComputedWordStyle = (word) => {
		if (word.word_animations) {
			const wordRef = word.type === 'word' ? word : row.widgets[wordIndex - 1];

			if (!wordRef) return 1;

			return Math.floor((wordRef.start_time - row.start_time) * fps);
		}
		return 1;
	};
	const classNames = useMemo(() => {
		const highlightClass =
			frame >= Math.floor(word.start_time * fps) &&
			frame < Math.floor(word.end_time * fps)
				? 'computedHighlightStyle'
				: '';

		const highlightLineClass =
			frame >= Math.floor(row.start_time * fps) &&
			frame < Math.floor(row.end_time * fps)
				? 'computedHighlightLineStyle'
				: '';
		return `${
			word.important ? 'important' : ''
		} ${highlightClass} ${highlightLineClass}`;
	}, [word, row, frame]);

	const getWordDelayStart = getComputedWordStyle(word);
	const word_duration = (word.end_time - word.start_time) * fps;

	return (
		<>
			{word.text ? (
				<AnimatedWords
					wordAnimation={word.word_animations}
					wordDelayStart={getWordDelayStart}
				>
					{wordIndex !== 0 && word.type === 'word' ? ' ' : null}
					<span
						className={`sb-text-shadow-sm relative inline-flex ${
							word.className ? word.className : ''
						} ${classNames} ${
							isWidthGreaterThan700 ? ' sb-text-shadow-lg' : ''
						}`}
						data-text={word.text}
					>
						{displayByLetter ? (
							<>
								{letters.map((letter, index) => {
									const isVisible =
										frame >=
										Math.floor(
											word.start_time * fps +
												index * (word_duration / letters.length) -
												10
										);
									return (
										<span
											key={index}
											style={{visibility: isVisible ? 'visible' : 'hidden'}}
										>
											{letter}
										</span>
									);
								})}
							</>
						) : (
							<>{word.text}</>
						)}
					</span>
					{word.break ? <br /> : null}
				</AnimatedWords>
			) : null}
		</>
	);
}
