import {useAnimatedWords} from './Hooks/useAnimatedWords';

export default function AnimatedWords({
	wordAnimation,
	wordDelayStart,
	children,
}) {
	const {scale, opacity, top} = useAnimatedWords({
		wordAnimation,
		wordDelayStart,
	});

	const transform = `scale(${scale})`;
	return (
		<span
			className="relative"
			style={{
				top,
				opacity,
				transform,
			}}
		>
			{children}
		</span>
	);
}
