import {CameraMotionBlur} from '@remotion/motion-blur';
import Emoji from './Emoji';
import {useAnimatedRows} from './Hooks/useAnimatedRows';
import Rows from './Rows';
import {useVideoConfig} from 'remotion';

export default function Subtitle({
	className,
	configStyle,
	animationClass,
	emojiAnimation,
	rows,
	emoji,
	randomNumberSeed,
	duration,
	frame,
	motionBlurActive,
	size,
}) {
	const {top, left, scale, rotate, opacity, letterSpacing} = useAnimatedRows({
		rowAnimation: animationClass,
		randomNumberSeed,
		rowDelayStart: duration,
	});
	const transform = `scale(${scale}) rotate(${rotate}deg)`;
	const {width, height} = useVideoConfig();
	const isLandscape = parseFloat((width / height).toFixed(2)) > 1.5;

	const subtitleCore = (
		<div
			style={{
				top,
				left,
				opacity,
				transform,
				width: '100%',
				padding: 'inherit',
				position: 'absolute',
				letterSpacing: `${letterSpacing}px`,
			}}
		>
			<div className={`row-container row-container-${rows.length}`}>
				{emoji && (
					<Emoji
						emojiAnimation={emojiAnimation}
						randomNumberSeed={randomNumberSeed}
						duration={duration}
						fontSize={configStyle.fontSize}
						emoji={emoji}
						size={size}
					/>
				)}
				<Rows
					rows={rows}
					animationClass={animationClass}
					randomNumberSeed={randomNumberSeed}
					duration={duration}
					frame={frame}
				/>
			</div>
		</div>
	);

	return (
		<h1
			className={`subtitle absolute z-20 w-full text-center ${
				isLandscape ? 'landscape' : 'portrait'
			} ${className}`}
			style={{
				color: configStyle.color,
				fontFamily:
					configStyle.fontFamily === 'Arial'
						? 'Arial'
						: `${configStyle.fontFamily}, Apple Color Emoji`,
				fontSize: `${configStyle.fontSize}px`,
				fontWeight: configStyle.fontWeight,
				top: `${configStyle.top}%`,
				textTransform: configStyle.fontUppercase ? 'uppercase' : 'none',
			}}
		>
			{motionBlurActive ? (
				<CameraMotionBlur shutterAngle={180} samples={10}>
					{subtitleCore}
				</CameraMotionBlur>
			) : (
				subtitleCore
			)}
		</h1>
	);
}
